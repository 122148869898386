<template>
  <v-row justify="center" class="ma-1">

    <v-col
        cols="12"
        class="my-4"
    >
      <h1 class="text-h4 primary--text text-center">Règles de gestion éditeurs</h1>
    </v-col>
    <v-col
        cols="12"
        sm="8"
        md="6"
    >
      <v-card>
        <div class="d-flex justify-center ma-4">
          <v-progress-circular
              v-if="loading"
              :size="50"
              color="primary"
              indeterminate
              justify-center
              class="ma-4"
          ></v-progress-circular>
        </div>
        <v-form
            ref="form"
            class="pa-6"
            v-model="valid"
            v-if="!loading"
        >
          <v-select
              v-model="editeurNameSelect"
              :items="editeurs"
              label="Editeur"
              item-value="codeEditeur"
              :loading="loadingForm"
          ></v-select>
          <Tooltip textToolTip="Valeur de stock minimal d'un ouvrage avant la recommande automatique.">
            <v-text-field
                v-if=" response.length && response[editIndexSelect].codeEditeur.length"
                v-model="response[editIndexSelect].minimum"
                label="Seuil minimum avant passage de commande"
                :disabled="editeurNameSelect === ''"
                type="number"
                :rules="numb2"
                required
                prefix="Livres :"
            ></v-text-field>
          </Tooltip>
          <Tooltip textToolTip="Intervalle minimum entre deux commandes.">
            <v-text-field
                v-if=" response.length && response[editIndexSelect].codeEditeur.length"
                v-model="response[editIndexSelect].schedule"
                label="Planificateur"
                type="number"
                :disabled="editeurNameSelect === ''"
                :rules="numb"
                required
                prefix="Jours :"
            ></v-text-field>
          </Tooltip>
          <Tooltip textToolTip="Date de la prochaine exécution du batch">
            <!-- Date picker -->
            <v-menu
                v-if=" response.length && response[editIndexSelect].codeEditeur.length"
                ref="menuStartDate"
                v-model="menuStartDate"
                :close-on-content-click="false"
                :return-value.sync="response[editIndexSelect].startDate"
                transition="scale-transition"
                offset-y
                min-width="280px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="response[editIndexSelect].startDate"
                    label="Prochaine exécution"
                    :disabled="editeurNameSelect === ''"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="response[editIndexSelect].startDate"
                  no-title
                  scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                    name="Annuler"
                    text
                    color="primary"
                    @click="menuStartDate = false"
                >
                  Annuler
                </v-btn>
                <v-btn
                    name="Valider"
                    text
                    color="primary"
                    @click="$refs.menuStartDate.save(response[editIndexSelect].startDate)"
                >
                  Valider
                </v-btn>
              </v-date-picker>
            </v-menu>
          </Tooltip>
          <!-- Date picker  END -->

          <v-switch
              v-if=" response.length && response[editIndexSelect].codeEditeur.length"
              v-model="response[editIndexSelect].active"
              :label="labelActif"
              :disabled="editeurNameSelect === ''"
          ></v-switch>
          <div class="d-flex justify-end">
              <v-btn
                  name="bouton exporter"
                  large
                  tile
                  depressed
                  color="primary"
                  :loading="exportLoading"
                  @click="exportCatalogEditeur"
                  :disabled="!valid || editeurNameSelect === ''"
                  class=" ma-4 AccessButton"
              >
                Exporter
                <v-icon right>mdi-chevron-right-circle-outline</v-icon>
              </v-btn>
            <v-btn
                name="bouton envoyer"
                large
                tile
                depressed
                color="primary"
                @click="sendNewRules"
                :disabled="!valid || editeurNameSelect === ''"
                class=" ma-4 AccessButton"
                :loading="loading"
            >
              Enregistrer
              <v-icon right>mdi-chevron-right-circle-outline</v-icon>
            </v-btn>
        </div>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import AdminService from "../../services/AdminService";
import Tooltip from "../Utility/Tooltip.vue";
import Utils from "../../utils/utils"

export default {
  components: {
    Tooltip,
  },
  data: () => ({
    numb: [
      (v) => (v <= 999 && v > 0) || `Merci d'entrer une valeur entre 1 et 365`,
    ],
    numb2: [
      (v) => (v <= 999 && v >= 0) || `Merci d'entrer une valeur entre 0 et 999`,
    ],
    startDate: "",
    menuStartDate: false,
    valid: false,
    loading: false,
    loadingForm: false,
    response: "",
    editIndexSelect: 0,
    editeurNameSelect: "",
    exportLoading: false,
  }),
  computed: {
    labelActif() {
      if (this.response[this.editIndexSelect].active === true) {
        return "Batch activé";
      } else {
        return "Batch désactivé";
      }
    },
    editeurs() {
      let arrayEditeurs = [];
      if (this.response.length) {
        this.response.map((item) => {
          arrayEditeurs.push(item.codeEditeur.toUpperCase());
        });
        return arrayEditeurs;
      } else {
        return arrayEditeurs;
      }
    },
  },
  watch: {
    editeurNameSelect: function () {
      const index = this.response.findIndex(
          (p) =>
              p.codeEditeur.toLowerCase() === this.editeurNameSelect.toLowerCase()
      );
      this.editIndexSelect = index;
    },
  },
  mounted() {
    this.loading = true;
    AdminService.getRules()
        .then(({data}) => {
          this.response = data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
  },
  methods: {
    /**
     * Download the export csv file from the selected editor
     */
    exportCatalogEditeur() {
      this.exportLoading = true
      AdminService.exportCatalogEditeur(this.editeurNameSelect.toLowerCase()).then((response) => {
        Utils.downloadFile(response.data, this.editeurNameSelect.toLowerCase() + '.csv')
      }).finally(() => this.exportLoading = false)
    },
    sendNewRules() {
      if (this.valid) {
        this.loading = true;
        AdminService.putRules(
            this.response[this.editIndexSelect].codeEditeur,
            this.response[this.editIndexSelect]
        )
            .then(() => {
              this.loading = false;
              this.$store.dispatch("setSnackbar", {
                color: "success",
                text: `La régle a été mise à jour`,
              });
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
              this.$store.dispatch("setSnackbar", {
                color: "error",
                text: "Une erreur est survenue, veuillez réessayer dans un moment.",
              });
            });
      }
    },
  },
};
</script>
<style scoped>
.AccessButton {
  border-radius: 4px;
  font-weight: 800;
  letter-spacing: 0;
}
</style>
