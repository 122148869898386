<template>
  <v-card
    height="100%"
    color="backgroundColor"
  >
    <Params />
  </v-card>
  
</template>

<script>
import Params from "./../components/Params/Params.vue";
export default {
  components: {
    Params,
  },
};
</script>